.fields {
    margin-top: 24px;
}

.button {
    composes: space-above from '../auth-shared.css';
}

.submit {
    margin-bottom: 8px;
}

.label {
    composes: space-above from '../auth-shared.css';
    display: flex;
    justify-content: space-between;
}

.button-contents-container {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.button-contents-container > svg {
    margin-right: 8px;
}

.loading-icon-container {
    margin-left: 8px;
}

.error-message {
    margin: 16px 0;
}

.login-container {
    composes: footer-container from '../auth-shared.css';
}

.login-container > a {
    margin-left: 4px;
}

.resend {
    display: flex;
    padding-top: 4px;
}

.resend button {
    padding: 0;
}

.recovery-button-container {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
